import React from "react"
import {
  PageInformation,
  Tagman,
  RightRail,
  Carousel,
  IntroContent,
  LinkList,
  Body,
  PagePromo,
  FooterCap,
  Breadcrumbs
} from "trv-ebus-tcom-reactcomponents"
import TransactionModuleWrapper from "../components/transaction-module-wrapper"
import { useStore } from "react-redux"

const getTagmanUrls = () => {
  const store = useStore()
  const utagUrl = store.getState().config.UTAG_URL
  return [utagUrl]
}

const getComponentsMap = () => {
  return new Map([
    [
      "Tagman",
      {
        init: (component) => {
          return (
            <Tagman
              pageId={component.tagman && component.tagman.pageId}
              appId={component.tagman && component.tagman.appId}
              tagManagerUrls={getTagmanUrls()}
            />
          )
        }
      }
    ],
    [
      "Transaction_Module",
      {
        init: (component) => <TransactionModuleWrapper {...component} />
      }
    ],
    [
      "Page_Information",
      {
        init: (component) => (
          <PageInformation
            title={component.pageTitle}
            description={component.pageDescription}
            keywords={
              component.metadata && component.metadata.keywords
                ? component.metadata.keywords.join(",")
                : ""
            }
            alternatePages={component.hrefLangItems}
            sharingTool={component.sharingTool}
            tags={component.metadata.tags}
          />
        )
      }
    ],
    [
      "Right_Rail",
      {
        init: (component) => <RightRail rails={component.rails} />
      }
    ],
    [
      "Carousel",
      {
        init: (component) => (
          <Carousel
            desktopHeroType={component.desktopHeroType}
            heroes={component.heroes}
          />
        )
      }
    ],
    [
      "Link_List",
      {
        init: (component) => (
          <LinkList
            listTitle={component.listTitle}
            listDescription={component.listDescription}
            listType={component.listType}
            layoutType={component.layoutType}
            links={component.links}
          />
        )
      }
    ],
    [
      "Body_Content",
      {
        init: (component) => (
          <Body
            pageType={component.pageType}
            rows={component.rows}
            metadata={component.metadata}
            articleHeading={component.articleHeading}
            sharingToolLocation={component.sharingToolLocation}
            bitlyUrl={component.bitlyUrl}
          />
        )
      }
    ],
    [
      "Intro_Content",
      {
        init: (component) => (
          <IntroContent
            heading={component.heading}
            copy={component.copy}
            readMoreLess={component.readMoreLess}
            includeContainer={component.includeContainer}
            sharingToolLocation={component.sharingToolLocation}
            bitlyUrl={component.bitlyUrl}
          />
        )
      }
    ],
    [
      "Page_Promo",
      {
        init: (component) => (
          <PagePromo
            layout={component.layout}
            fullPromo={component.fullPromo}
            halfPromos={component.halfPromos}
          />
        )
      }
    ],
    [
      "Footer_Cap",
      {
        init: (component) => (
          <FooterCap
            imageSrc={component.fullPromo.imageSrc}
            imageAlt={component.fullPromo.imageAlt}
            heading={component.fullPromo.heading}
            subheading={component.fullPromo.subheading}
            callToAction={component.fullPromo.callToAction}
          />
        )
      }
    ],
    [
      "Disclaimer",
      {
        init: (component) => {
          return [
            {
              text: component.disclaimerText
            }
          ]
        }
      }
    ],
    [
      "Breadcrumbs",
      {
        init: (component) => {
          return (
            <Breadcrumbs
              breadcrumbs={component.breadcrumbs}
              sharingToolLocation={component.sharingToolLocation}
              bitlyUrl={component.bitlyUrl}
            />
          )
        }
      }
    ]
  ])
}

export default getComponentsMap
