import getBreadcrumbs from "../utilities/getBreadcrumbs"

const getComponentKey = (name, dcr) => {
  if (name === "Transaction_Module") {
    if (dcr.transactionModules && dcr.transactionModules.length > 0) {
      switch (dcr.transactionModules[0].tabInc) {
        case "pi_dynamic_cta": // remove before production
        case "northfield_find_agent":
          return "Dynamic_Cta"
      }
    }
  }

  return name
}

const loadComponents = (availableComponentsMap, components, allEcsSitemap, ecsSitemap) => {
  let componentDict = {}
  let sharingTool = {
    location: null,
    bitlyUrl: null
  }

  components.map((component) => {
    const name = component.name
    const dcr =
      component.segments[0].segmentItemDetails[0].parameterItemDetails[0].dcr
    if (dcr) {
      if (component.name === "Page_Information") {
        if (dcr.sharingTool) {
          sharingTool["location"] = dcr.sharingTool["location"]
          sharingTool["bitlyUrl"] = dcr.sharingTool["bitlyUrl"]
        }
      }

      if (['Body_Content', 'Intro_Content'].includes(component.name)) {
        if (sharingTool) {
          dcr["sharingToolLocation"] = sharingTool.location
          dcr["bitlyUrl"] = sharingTool.bitlyUrl
        }
      }

      const mappedComponent = availableComponentsMap.get(component.name)

      if (mappedComponent) {
        componentDict[getComponentKey(name, dcr)] = mappedComponent.init(dcr)
      }

      if (name === "Page_Information") {
        const tagman = availableComponentsMap.get("Tagman")
        if (tagman) {
          componentDict["Tagman"] = tagman.init(dcr)
        }
      }
    }
  })

  if (allEcsSitemap && ecsSitemap) {
    const breadcrumbsDcr = {
      breadcrumbs: getBreadcrumbs(allEcsSitemap, ecsSitemap),
      sharingToolLocation: sharingTool.location,
      bitlyUrl: sharingTool.bitlyUrl
    }

    const breadcrumbs = availableComponentsMap.get("Breadcrumbs")
    componentDict["Breadcrumbs"] = breadcrumbs.init(breadcrumbsDcr)
  }

  return componentDict
}

export default loadComponents
