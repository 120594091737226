import React, { useState } from "react"
import { navigate } from "gatsby"
import { TransactionModule } from "trv-ebus-tcom-reactcomponents"
import { States } from "../../constants"

const NorthfieldTM = ({ transactionModules }) => {
  const [error, setError] = useState(null)
  const [selectedProduct, setSelectedProduct] = useState({})

  const validateState = () => {
    if (!selectedProduct || selectedProduct.value === "Select Your State...") {
      setError({ message: "State is required", classes: "state-error-msg" })
      return false
    }

    if (error) {
      setError(null)
    }

    return true
  }

  const handleSubmit = () => {
    if (validateState()) {
      navigate(`/find-agent?state=${selectedProduct.key}`)
    }
  }

  return (
    <TransactionModule
      tabInc={transactionModules[0].tabInc}
      piDynamicCta={{
        parents: [
          {
            labelDetail: { key: "", value: "" },
            children: States.map((state) => ({
              labelDetail: {
                key: state.value,
                value: state.name
              }
            }))
          }
        ]
      }}
      title="Find a General Agent"
      submitButton="Search"
      placeholder="Select Your State..."
      classes="northfield"
      selectedProduct={selectedProduct}
      setSelectedProduct={(product) => setSelectedProduct(product)}
      onSubmit={handleSubmit}
      error={error}
    />
  )
}

export default NorthfieldTM
