const createBreadcrumbsMap = (allBreadcrumbs) => {
  let breadcrumbMap = {}
  for (var i = 0; i < allBreadcrumbs.nodes.length; i++) {
    const node = allBreadcrumbs.nodes[i]
    breadcrumbMap[node.id] = node
  }
  return breadcrumbMap
}

const getBreadcrumbs = (allBreadcrumbs, currentBreadcrumb) => {
  if (!allBreadcrumbs || !currentBreadcrumb) {
    return null
  }

  const breadcrumbMap = createBreadcrumbsMap(allBreadcrumbs)
  let breadcrumbs = []

  let breadcrumb = currentBreadcrumb

  while (breadcrumb) {
    if (breadcrumb.content.visibleInBreadcrumbs === "true") {
      breadcrumbs.push({
        link: breadcrumbMap[breadcrumb.id].content.link.value
          ? breadcrumbMap[breadcrumb.id].content.link.value
          : "",
        linkText: breadcrumbMap[breadcrumb.id].content.label.value
      })
    }
    breadcrumb = breadcrumbMap[breadcrumb.parent && breadcrumb.parent.id]
  }

  return breadcrumbs.reverse()
}

export default getBreadcrumbs
