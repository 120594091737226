import React from "react"
import NorthfieldTM from "./northFieldTM"
import TcomTM from "./tcomTM"

const TransactionModuleWrapper = (component) => {
  switch (component.transactionModules[0].tabInc) {
    case "pi_dynamic_cta": // this case is here to test tcom sticky cta, needs to be removed before production
     return <TcomTM {...component} />
    case "northfield_find_agent":
     return <NorthfieldTM {...component} />
  }
}

export default TransactionModuleWrapper
